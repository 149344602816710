// import logo from "./logo.svg";

import { Link, Route, Switch } from "react-router-dom";
import { Container, Nav, Navbar, Row } from "react-bootstrap";
import EnlMap from "./EnlMap";
import CommList from "./CommList";
import AddUpdate from "./AddUpdate";

// ToDo: DB Storage?

function App() {
  return (
    <Container>
      <Navbar sticky="top" expand="md" variant="dark">
        <Navbar.Toggle />
        <Container>
          <Nav className="mr-auto">
            <Nav.Link to="/" as={Link}>
              jrvs.me
            </Nav.Link>
            <Nav.Link to="/regions" as={Link}>
              map
            </Nav.Link>
            <Nav.Link to="/request-change" as={Link}>
              request change
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Row className="welcome-row">
        <h1>Welcome! Get in touch with your local Enlightened community.</h1>
      </Row>
      <Row>
        <Switch>
          <Route exact path={["/map", "/maps", "/regions"]}>
            <EnlMap />
          </Route>
          <Route exact path="/request-change">
            <AddUpdate />
          </Route>
          <Route exact path="/">
            <CommList />
          </Route>
          {/* Should we put the shortcuts here? */}
        </Switch>
      </Row>
    </Container>
  );
}

export default App;
