import React from "react";
import { fromLonLat } from "ol/proj";
// import { Point } from "ol/geom";
import "ol/ol.css";
import { Polygon } from "ol/geom";
import { RFeature, RLayerVector, RMap, ROSM, RPopup, RStyle } from "rlayers";
import { data } from "./data";

// ToDo: Add popup for communities
// ToDo: Figure out what to do for overlapping communities

// Create a map, its size is set in the CSS class example-map
// const lat = Math.random() * 180 - 90;
// const lng = Math.random() * 360 - 180;
const lat = 37.3026409;
const lng = -23.367068;
const center = fromLonLat([lng, lat]);

function createRFeature(region) {
   if (region.border.length > 2) {
      let redShade = Math.floor(Math.random() * 127) + 120;
      let greenShade = Math.floor(Math.random() * 127) + 120;
      let blueShade = Math.floor(Math.random() * 40);

      // rlayers needs the same start and end to draw a polygon
      let border = region.border;
      if (border[0] !== border[border.length - 1]) {
         border.push(border[0]);
      }

      // not that we're all set, let's put it together into a shape
      // that can be passed back to the renderer
      return (
         <RFeature
            key={region.shortcut}
            geometry={
               new Polygon([
                  border.map((ll) => {
                     return fromLonLat([ll.lng, ll.lat]);
                  }),
               ])
            }
         >
            <RStyle.RStyle>
               <RStyle.RFill color={[redShade, greenShade, blueShade, 0.15]} />
               <RStyle.RStroke
                  color={[redShade, greenShade, blueShade, 0.75]}
                  width={3}
               />
            </RStyle.RStyle>
            <RPopup trigger={"click"} className="region-overlay">
               <a href={"https://jrvs.me/" + region.shortcut}>{region.name}</a>
            </RPopup>
         </RFeature>
      );
   }
}

//const center = fromLonLat([37.757, 122.472]);
function EnlMap() {
   return (
      <RMap className="map" initial={{ center: center, zoom: 1 }}>
         <ROSM />
         <RLayerVector zIndex={1}>
            {data.regions.map((region) => {
               return createRFeature(region);
            })}
         </RLayerVector>
      </RMap>
   );
}

export default EnlMap;
