import { data } from "./data";
import { Container, Table } from "react-bootstrap";

// ToDo: Different tabs for regions and affinity groups?

function showRegions() {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Region</th>
          <th>jrvs.me/{"{shortcut}"}</th>
        </tr>
      </thead>
      <tbody>
        {data.regions
          .sort((a, b) => {
            return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
          })
          .map((region) => {
            return (
              <tr key={region.shortcut}>
                <td>
                  <a href={region.url} target="_blank" rel="noreferrer">
                    {region.name}
                    <div className="link-subtext">{region.area}</div>
                  </a>
                </td>
                <td>
                  <a href={region.url} target="_blank" rel="noreferrer">
                    /{region.shortcut}
                  </a>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

function showGroups() {
  return (
    <Table striped>
      <tbody>
        {data.groups
          .sort((a, b) => {
            return a.name > b.name;
          })
          .map((group) => {
            return (
              <tr key={group.name}>
                <td>
                  <a href={group.url} target="_blank" rel="noreferrer">
                    {group.name}
                  </a>
                </td>
                <td>
                  <a href={group.url} target="_blank" rel="noreferrer">
                    /{group.shortcut}
                  </a>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

function CommList() {
  return (
    <Container className="inner">
      <h2>Regions</h2>
      {showRegions()}
      <h2>Others</h2>
      {showGroups()}
    </Container>
  );
}

export default CommList;
