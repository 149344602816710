import React from "react";

// ToDo: Registration method
function AddUpdate() {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdFw8KT486nOkbDyUfL_7aWJfXr3Lod0XmarkumvIWXv0WvfQ/viewform?embedded=true"
      width="640"
      height="2009"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      title="GoogleCommunityForm"
    >
      Loading…
    </iframe>
  );
}

export default AddUpdate;
